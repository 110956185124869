<template>
  <div class="backgroundLoin">
    <div class="bgTop">
      <img src="@/assets/scoreViewsPad/PadScoreLogin.png" />
    </div>
    <el-card class="box-card">
      <div class="boxTit">评分入口</div>
      <el-form
        label-position="right"
        label-width="60px"
        ref="formLion"
        :rules="rules"
        :model="formLion"
      >
        <el-form-item label="登录方式" v-if="0">
          <el-radio-group v-model="formLion.loginType">
            <el-radio :disabled="disabled" :label="1">账号</el-radio>
            <el-radio :disabled="disabled" :label="2">手机号</el-radio>
            <el-radio :disabled="disabled" :label="3">邮箱</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="account" label="账号">
          <el-input
            prefix-icon="el-icon-user-solid"
            v-model="formLion.account"
            :placeholder="accountText"
            :disabled="disabled"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password" label="密码">
          <el-input
            v-model="formLion.password"
            placeholder="请输入密码"
            show-password
            prefix-icon="el-icon-lock"
            :disabled="disabled"
          ></el-input>
        </el-form-item>
        <el-button
          v-throttle
          type="primary"
          :loading="loadingBtn"
          @click="onLogin('formLion')"
          class="loginBtn"
          >登录</el-button
        >
      </el-form>
      <!-- <div class="clearfix" v-if="0">
        <router-link to="./register">
          <el-link type="primary" class="rf">开通账号</el-link>
        </router-link>
      </div>-->
    </el-card>
  </div>
</template>

<script>
import { login } from '@/api/auth.js'
import { setToken, setName } from '@/utils/token.js'
export default {
  name: 'scoreLogin',
  data() {
    return {
      formLion: {
        account: '',
        // 登录类型,1为账号登录，2为手机号登录，3为邮箱登录
        loginType: 1,
        password: '',
        userType: 0,
        loginTerminalType: 13,
      },
      accountText: '请输入用户名',
      loadingBtn: false,
      disabled: false,
      // 验证
      rules: {
        account: [{ required: true, message: '账号不能为空', trigger: 'blur' }],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
        ],
      },
    }
  },
  watch: {
    'formLion.loginType': function (newVal, oldVal) {
      // this.formLion.userType = newVal;
      switch (newVal) {
        case 2:
          this.accountText = '请输入手机号'
          break
        case 3:
          this.accountText = '请输入邮箱'
          break
        default:
          this.accountText = '请输入账号'
      }
    },
  },
  // 在组件的 created 生命周期钩子中添加监听回车事件
  created() {
    document.addEventListener('keydown', this.handleEnterKey)
  },

  // 在组件销毁时移除监听回车事件，避免内存泄漏
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleEnterKey)
  },
  methods: {
    // 处理回车事件的方法
    handleEnterKey(event) {
      if (event.keyCode === 13) {
        this.onLogin('formLion')
      }
    },

    onLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.disabled = this.loadingBtn = true
          // 在此发送请求
          login(this.formLion)
            .then((res) => {
              this.disabled = this.loadingBtn = false
              console.log(res)
              if (res.success) {
                // 存储Token vuex 或者 浏览器上储存
                setToken(res.data.token)
                setName(this.formLion.account)
                this.$message.success('登录成功，正在转跳···')
                // 成功后跳转路由
                setTimeout(() => {
                  this.$router.replace('/chooseExamRoom')
                }, 500)
              } else {
                this.$message.warning(res.msg)
              }
            })
            .catch((err) => {
              this.disabled = false
              this.loadingBtn = false
              this.$message.warning('登录失败')
            })
        } else {
          return false
        }
      })
    },
  },
}
</script>
<style scoped>
@media screen and (min-width: 960px) {
  .bgTop {
    height: 350px;
    margin-bottom: 56px;
    background: url(../../assets/scoreViewsPad/PcScoreLoginBg.png) no-repeat;
  }
}
@media screen and (max-width: 960px) {
  .bgTop {
    height: 230px;
    margin-bottom: 40px;
    background: url(../../assets/scoreViewsPad/PadScoreLoginBg.png) no-repeat;
  }
}
.bgTop {
  width: 100vw;
  padding: 20px;
  box-sizing: border-box;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.bgTop img {
  max-width: 100%;
  max-height: 100%;
}
.box-card {
  margin: 0 auto;
  width: 412px;
  height: 330px;
}
.boxTit {
  letter-spacing: 6px;
  font-weight: bold;
  text-align: center;
  font-size: 27px;
  height: 80px;
  line-height: 60px;
}
.loginBtn {
  background: #4cd8f9;
  border-color: #4cd8f9;
  box-shadow: 0px 2px 4px 1px #4cd9f975;
  padding: 10px 30px;
  margin: 0 auto;
  margin-top: 40px;
  display: block;
}
::v-deep
  .el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before {
  content: ' ';
  color: #fff;
  margin-right: 4px;
}
</style>
